var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex flex-row mb-0 above-table"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche (par : Nom ou ingrédient)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"sort-by":'name',"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 25, 50],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"to":("/admin/marmiton-edit-ingredient-group/" + (item.id)),"elevation":"0","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","fab":"","small":""},nativeOn:{"click":function($event){_vm.dialogModel.dialog = !_vm.dialogModel.dialog;
              _vm.dialogModel.id = item.id;
              _vm.dialogModel.groupName = item.name;}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1)]}},{key:"item.ingredients",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.ingredients}},[_vm._v(" "+_vm._s(item.ingredients)+" ")])]}}])})],1),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('del-confirmation',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"id":_vm.dialogModel.id,"name":_vm.dialogModel.groupName},on:{"openDialog":_vm.openDialog,"removedItem":_vm.refreshWindow}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }