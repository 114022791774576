<template>
  <div>
    <p class="text-2xl mb-6">Groupes d'ingrédients</p>
    <v-col cols="12" class="d-flex flex-row-reverse">
      <v-btn
        color="primary"
        link
        :to="{ name: 'admin-marmiton-create-ingredient-group' }"
      >
        <v-icon class="mr-3">{{ icons.mdiPlusBox }}</v-icon
        >Créer un nouveau groupe d'ingrédients</v-btn
      >
    </v-col>
    <v-col cols="12">
      <groups-table />
    </v-col>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import GroupsTable from "@/components/admin/marmiton/tables/IngredientGroupsTable";

export default {
  name: "IngredientGroups",
  components: {
    GroupsTable,
  },
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
