<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">
        Etes-vous sûr de vouloir supprimer le groupe d'ingrédients "{{ name }}"
        ?
      </p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="confirmationForm">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="deleteGroup"
                  >Supprimer
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="$emit('openDialog', false)"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import { NOTIF_DISPLAY_DURATION } from "@/utils/constants";

export default {
  name: "GroupDelConfirmation",
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteGroup() {
      const { status } = await axios.delete(
        `/regie/marmiton/ingredient-group/${this.id}`
      );
      if (status === 204) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Le groupe d'ingrédients ${this.name} a été supprimé.`,
          color: "success",
          timeout: NOTIF_DISPLAY_DURATION,
        });

        setTimeout(() => this.$emit("removedItem"), NOTIF_DISPLAY_DURATION);
      }
    },
  },
};
</script>
