<template>
  <v-card>
    <v-card-title>
      <div class="d-flex flex-row mb-0 above-table">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche (par : Nom ou ingrédient)"
          single-line
          hide-details
        ></v-text-field>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'name'"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        class="elevation-1"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              :to="`/admin/marmiton-edit-ingredient-group/${item.id}`"
              class="mx-2"
              elevation="0"
              fab
              small
            >
              <v-icon color="primary">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click.native="
                dialogModel.dialog = !dialogModel.dialog;
                dialogModel.id = item.id;
                dialogModel.groupName = item.name;
              "
            >
              <v-icon color="secondary">
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.ingredients="{ item }">
          <div class="truncateCell" :title="item.ingredients">
            {{ item.ingredients }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <del-confirmation
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :name="dialogModel.groupName"
        @openDialog="openDialog"
        @removedItem="refreshWindow"
      ></del-confirmation>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "@axios";
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import debounce from "lodash/debounce";

import DelConfirmation from "@/components/admin/marmiton/modals/GroupDelConfirmation";
import router from "@/router";

export default {
  name: "IngredientGroupsTable",
  components: { DelConfirmation },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      loading: true,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      dialogModel: {
        dialog: false,
        id: -1,
        groupName: "",
      },
      headers: [
        {
          text: "Nom",
          align: "start",
          value: "name",
        },
        {
          text: "ingrédients",
          value: "ingredients",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
          width: "9rem",
        },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        page: page - 1,
        limit: itemsPerPage,
        ...(this.search ? { search: this.search } : {}),
      };

      const { data } = await axios.get("/regie/marmiton/ingredient-groups", {
        params: queryParams,
      });
      this.items = data.items;
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;

      this.loading = false;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
    },
    refreshWindow() {
      router.go();
    },
  },
  watch: {
    options: {
      handler() {
        this.debouncedUpdateData();
      },
    },
    search() {
      this.options.page = 1;
      this.debouncedUpdateData();
    },
  },
};
</script>

<style lang="scss" scoped>
.above-table {
  width: 100%;
}

.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>
